<template>
  <div class="fields-config search-config">
    <a-input
      v-model="searchValue"
      class="fields-config__input"
      :size="controlsSize"
      :placeholder="$t(`base.placeholder.search`)"
      :type="selectedFieldType"
      @keyup.enter="$emit('applyParams')"
    >
      <template
        #addonAfter
        v-if="options.length > 1"
      >
        <a-popover
          v-model="showConfig"
          trigger="click"
          placement="bottomRight"
        >
          <a-icon type="file-search" />
          <template #content>
            <div class="fields-config__popover">
              <div class="filter__popover">
                <div
                  v-for="field in options"
                  :key="field.value"
                  :class="['filter__titles', { active: isFieldSelected(field) }]"
                  @click="selectedField = field.value"
                >
                  <span>{{ field.label || field.value }}</span>
                </div>
              </div>
            </div>
          </template>
        </a-popover>
      </template>
    </a-input>
  </div>
</template>

<script>
import configFormMixin from './configFormMixin';

export default {
  name: 'SearchConfig',
  mixins: [configFormMixin],
  props: {
    value: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    controlsSize: {
      type: String,
      default: 'large',
    },
  },
  data() {
    return {
      showConfig: false,
      selectedFieldType: 'text',
    };
  },
  computed: {
    searchValue: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit('setSearchValue', value);
      },
    },
    selectedField: {
      get() {
        return this.value;
      },
      set(value) {
        this.showConfig = false;
        this.$emit('input', value);
      },
    },
    options() {
      return this.configLayout.searchField.options(this.entityFields, this.entityType);
    },
  },
  watch: {
    selectedField() {
      this.updateSelectedFieldType();
    },
  },
  mounted() {
    this.updateSelectedFieldType();
  },
  methods: {
    isFieldSelected({ value }) {
      return this.selectedField === value;
    },
    updateSelectedFieldType() {
      const renderer = this.options.find(this.isFieldSelected)?.renderer ?? '';
      this.selectedFieldType =
        {
          integer: 'number',
          number: 'number',
          real_number: 'number',
          string: 'text',
          text: 'text',
        }[renderer] ?? 'text';
    },
  },
};
</script>

<style lang="scss">
.search-config {
  .anticon {
    padding: 10px 14px 11px;
    font-size: 17px;
  }
  .ant-input-group-addon {
    padding: 0;
    cursor: pointer;
  }
  .ant-input::-webkit-inner-spin-button {
    display: none;
  }
}

.ant-input-group-addon {
  background: #fff;
}
</style>
