import { render, staticRenderFns } from "./SearchConfig.vue?vue&type=template&id=fff2e78c&"
import script from "./SearchConfig.vue?vue&type=script&lang=js&"
export * from "./SearchConfig.vue?vue&type=script&lang=js&"
import style0 from "./SearchConfig.vue?vue&type=style&index=0&id=fff2e78c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports